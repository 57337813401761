import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../App";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout";

function ResetPasswordPage() {
	let navigate = useNavigate();
	const context = useContext(AppContext);
	const [checked, setChecked] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [password, setPassword] = useState("");
	const [cPassword, setCpassword] = useState("");
	const [canGoNext, setCanGoNext] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	let tenantName = context?.tenantName;

	let params = useParams();
	let token = params?.token;

	const checkToken = (subdomain) => {
		const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST
		const appDomain = window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN
		const tenantHost = appHost.replace(appDomain, subdomain + '.' + appDomain)
		axios
			.get(tenantHost + "/api/v1/auth/reset-password/" + token)
			.then((res) => {
				setChecked(true);
			})
			.catch((error) => {
				return navigate('/')
			});
	};

	useEffect(() => {
		if (window.location.origin === (window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST)) return;
		const subdomain = window.location.hostname.replace("." + (window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN), "");
		checkToken(subdomain);
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!canGoNext) return;
		const subdomain = window.location.hostname.replace("." + (window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN), "");
		const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST
		const appDomain = window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN
		const tenantHost = appHost.replace(appDomain, subdomain + '.' + appDomain)

		axios
			.post(tenantHost + "/api/v1/auth/reset-password/" + token, {
				password: password,
			})
			.then((res) => {
				window.location = res.data.redirectUrl
			})
			.catch((error) => {
				setErrorMsg("Error occured, please try again.");
			});
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleConfirmPasswordChange = (e) => {
		setCpassword(e.target.value);
	};

	useEffect(() => {
        if (!password) {
            setCanGoNext(false);
        }
        if (password && password.length < 8) {
            setErrorMsg("The password must be at least 8 characters");
			setCanGoNext(false);
            return
        }
        if (password !== cPassword) {
			setErrorMsg("The password does not match");
			setCanGoNext(false);
            return
		}
        setErrorMsg("");
		setCanGoNext(true);
	}, [password, cPassword]);

	return (
		<Layout bg="blue" loading={!checked}>
			<div className="right-wrapper">
				<div className="inner-wrapper">
					<div className="top">Reset Password</div>
					<div className="second">Please set your password</div>
					<form onSubmit={handleSubmit}>
						<div className="form">
							<div className="form-input">
								<input
									name="password"
									placeholder="New Password"
									type={showPassword ? "text" : "password"}
									required
									onChange={handlePasswordChange}
								/>
								<div className="input-suffix">
									<div
										className="show-password"
										style={{ cursor: "pointer" }}
										onClick={() => setShowPassword(!showPassword)}
										value={password}
									>
										{showPassword ? (
											<img src={process.env.PUBLIC_URL + "/img/invisible.svg"} alt="" />
										) : (
											<img src={process.env.PUBLIC_URL + "/img/view.svg"} alt="" />
										)}
									</div>
								</div>
							</div>
							<div className="form-input">
								<input
									name="password-confirm"
									placeholder="Re-enter New Password"
									type={showPassword ? "text" : "password"}
									required
									onChange={handleConfirmPasswordChange}
									value={cPassword}
								/>
								<div className="input-suffix">
									<div className="show-password" style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? (
											<img src={process.env.PUBLIC_URL + "/img/invisible.svg"} alt="" />
										) : (
											<img src={process.env.PUBLIC_URL + "/img/view.svg"} alt="" />
										)}
									</div>
								</div>
							</div>
							{errorMsg ? <div className="error-msg">{errorMsg}</div> : ""}
							<input type="submit" className="button-primary" value="Confirm" disabled={!canGoNext} />
						</div>
					</form>
				</div>
			</div>
		</Layout>
	);

}

export default ResetPasswordPage;
