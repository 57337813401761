import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Redirect() {
	let navigate = useNavigate();
	useEffect(() => {
		return navigate("/");
	});
	return <></>;
}

export default Redirect;
