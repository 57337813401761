import React, { useContext, useState } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import Layout from "../../Layout";
import { Link } from "react-router-dom";

function ForgetPasswordPage() {
	const context = useContext(AppContext);
	const [email, setEmail] = useState('');
	const [errorMsg, setErrorMsg] = useState("");
	const [msg, setMsg] = useState("");
	const [sent, setSent] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (sent) return
		const subdomain = window.location.hostname.replace("." + (window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN), "");
		const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST
		const appDomain = window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN;
		const tenantHost = appHost.replace(appDomain, subdomain + '.' + appDomain)

		axios
			.post(tenantHost + "/api/v1/auth/forget-password", {
				email: email,
			})
			.then((res) => {
				setSent(true)
				setErrorMsg('')
				setMsg('Reset link has been sent. Please check your mail inbox.')
			})
			.catch((error) => {
				setErrorMsg("Error occured, please try again.");
			});
	};

	return (
		<Layout bg="blue">
			<div className="right-wrapper">
				<div className="inner-wrapper">
					<div className="top">Forget Password</div>
					<div className="second">Please enter your email account and you will receive a password reset link after submit.</div>
					<form onSubmit={handleSubmit}>
						<div className="form">
							<div className="form-input">
								<input
									name="email"
									placeholder="Email"
									type="email"
									required
									onChange={(e) => setEmail(e.target.value)}
									value={email}
								/>
							</div>
							{msg ? <div className="msg">{msg}</div> : ""}
							{errorMsg ? <div className="error-msg">{errorMsg}</div> : ""}
							<input type="submit" className="button-primary" value="Confirm" disabled={!email} />
						</div>
					</form>
				</div>
				<div className="bottom">
					<Link to="/">Back to Login</Link>
				</div>
			</div>
		</Layout>
	);

}

export default ForgetPasswordPage;
