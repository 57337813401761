import React, { useState } from "react";
import axios from "axios";

function Tenant() {
	const [errorMsg, setErrorMsg] = useState("");

	const checkTenant = (e) => {
		e.preventDefault();

		const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST;
		const appDomain = window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN;
		axios
			.post(appHost + "/api/v1/tenants/check", {
				tenant: e.target.shortcode.value,
			})
			.then((res) => {
				const subdomain = e.target.shortcode.value;

				const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST
				const tenantHost = appHost.replace(appDomain, subdomain + '.' + appDomain)

				window.location = tenantHost;
				setErrorMsg("");
			})
			.catch((error) => {
				setErrorMsg("Tenant Not Found, please try again.");
			});
	};

	return (
		<div className="right-wrapper">
			<div className="inner-wrapper">
				<div className="top">Welcome to Smartoo</div>
				<div className="second">Please enter the shortcode of the Smartoo URL you wish to login to.</div>
				<form onSubmit={checkTenant}>
					<div className="form">
						<label htmlFor="">Smartoo URL</label>
						<div className="form-input">
							<input name="shortcode" placeholder="Shortcode" className={errorMsg ? 'error' : ''} required />
							<div className="input-suffix">.smto.io</div>
						</div>
            {errorMsg ? <div className="error-msg">{errorMsg}</div> : ""}
						<input type="submit" className="button-primary" value="Continue" />
					</div>
				</form>
			</div>
			<div className="bottom">
				<span className="text-muted">Don’t have an Account? </span>
				<a href="https://smartoo.io/create-account">Sign Up</a>
			</div>
		</div>
	);

	return (
		<div>
			<p className="text-center text-3xl">Welcome to XenWork.</p>
			<form className="flex flex-col pt-3 md:pt-8" onSubmit={checkTenant}>
				<div className="flex flex-col pt-4">
					<label htmlFor="email" className="text-lg">
						Pleas Enter your Account ID
					</label>
					<input
						type="text"
						id="account_id"
						placeholder="e.g. xenwork"
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
						required
					/>
				</div>
				{errorMsg ? <span className="text-red-500 mt-2">{errorMsg}</span> : ""}
				<input type="submit" value="Next" className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8" />
			</form>
			{/* <div className="text-center pt-12 pb-12">
              <p>Don't have an account? <a href="register.html" className="underline font-semibold">Register here.</a></p>
            </div> */}
		</div>
	);
}

export default Tenant;
