import React, { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

function Login({ tenantName }) {
  const [errorMsg, setErrorMsg] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    const subdomain = window.location.hostname.replace('.' +  (window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN), '')
    const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST
		const appDomain = window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN
		const tenantHost = appHost.replace(appDomain, subdomain + '.' + appDomain)

    axios.post(tenantHost + '/api/v1/auth/login', {
      'email': e.target.email.value, 
      'password': e.target.password.value, 
    }).then((res) => {
      window.location = tenantHost + '/scheduler'
    }).catch((error) => {
      setErrorMsg('Account Not Found / Password Incorrect, please try again.')
    })
  }

  return (
    <div className="right-wrapper">
			<div className="inner-wrapper">
				<div className="top">Hello, {tenantName}</div>
				<form onSubmit={handleSubmit}>
					<div className="form">
						<div className="form-input">
							<input name="email" placeholder="Email" type="email" required />
						</div>
            <div className="form-input">
							<input name="password" placeholder="Password" type={showPassword ? 'text' : 'password'} required />
              <div className="input-suffix">
                <div className="show-password" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                  {
                    showPassword ? <img src={process.env.PUBLIC_URL + '/img/invisible.svg'} alt="" /> : <img src={process.env.PUBLIC_URL + '/img/view.svg'} alt="" />
                  }
                </div>
              </div>
						</div>
            {errorMsg ? <div className="error-msg">{errorMsg}</div> : ""}
						<input type="submit" className="button-primary" value="Login" />
					</div>
				</form>
			</div>
			<div className="bottom">
				<Link to="/forget-password">Forget Password</Link>
			</div>
		</div>
  )
}

export default Login
