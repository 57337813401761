import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import InvitationPage from "./pages/Invitation/InvitationPage";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";
import ForgetPasswordPage from "./pages/ForgetPassword/ForgetPasswordPage"
import LoginPage from './pages/Login/LoginPage'
import axios from "axios";
import Redirect from "./pages/Redirect";

const AppContext = React.createContext();
export { AppContext }

function App() {

    let context = {}

	const [tenantName, setTenantName] = useState()
	const [isLoading, setIsLoading] = useState(true)

	const checkTenant = async (tenant) => {
		await axios.post((window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST) + '/api/v1/tenants/check', {
			'tenant': tenant
		}).then((res) => {
			setTenantName(res.data.name)
		}).catch((error) => {
		})
	}

	const checkIsLoggedIn = async (subdomain) => {
		const appHost = window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST
		const appDomain = window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN
		const tenantHost = appHost.replace(appDomain, subdomain + '.' + appDomain)
		await axios.get(tenantHost + '/api/v1/auth/getToken').then((res) => {
			window.location = tenantHost + '/scheduler'
		}).catch((error) => {
		})
	}

	useEffect(() => {
		if (window.location.origin === (window?._env_?.APP_HOST ?? process.env.REACT_APP_APP_HOST)) {
			setIsLoading(false)
			return
		}
        const subdomain = window.location.hostname.replace('.' +  (window?._env_?.APP_DOMAIN ?? process.env.REACT_APP_APP_DOMAIN), '')
		const init = async () => {
			await checkIsLoggedIn(subdomain)
			await checkTenant(subdomain)
			setIsLoading(false)
		}
		init()
	}, [])

    context = {
        ...context,
        tenantName,
		isLoading,
    }

	return (
        <AppContext.Provider value={context}>
            <Routes>
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/invitation/:token" element={<InvitationPage />} />
                <Route path="/forget-password" element={<ForgetPasswordPage />} />
                <Route path="/" element={<LoginPage />} />
				<Route path="*" element={<Redirect />} />
            </Routes>
        </AppContext.Provider>
	);
}

export default App;
