import React from 'react'

function Layout({ children, bg = 'orange', loading = false}) {
  return (
    <div className="main-container">
        {
            loading ?
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            :
            <>
                <div className="left">
                    {
                        bg === 'orange' ? <img className="bg" src={process.env.PUBLIC_URL + '/img/login_bg.png'} alt="" /> : <img className="bg" src={process.env.PUBLIC_URL + '/img/login_bg_2.png'} alt="" />
                    }
                    <div className="inner">
                        <img className="logo" src={process.env.PUBLIC_URL + '/img/logo/logo_smartoo_short_white.svg'} alt="" />
                    </div>
                </div>
                <div className="right">
                    { children }
                </div>
            </>
        }
    </div>
  )
}

export default Layout