import React, { useContext } from 'react';
import Login from './components/login'
import Tenant from './components/tenant'
import { AppContext } from '../../App'
import Layout from '../../Layout';

function LoginPage() {
	const context = useContext(AppContext);

	let tenantName = context?.tenantName

	return (
		<Layout bg={!tenantName ? 'orange' : 'blue'} loading={context.isLoading}>
		{
			!tenantName ? <Tenant /> : <Login tenantName={tenantName} />
		}
		</Layout>
	);
}

export default LoginPage;
